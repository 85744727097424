import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Image
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import anonymousLogo from "assets/img/anonymous-logo.png"

function TablePort(props) {
  const { name, status, budget, version, cycle } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr>
      <Td minWidth={{ sm: "100px", lg: "100px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Image src={anonymousLogo} h={"24px"} w={"24px"} me="18px" />
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {budget}
        </Text>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={status === "running" ? "green" : "red"}
          fontWeight="bold"
          pb=".2rem"
        >{status}</Text>
      </Td>
      <Td>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".2rem"
        >{cycle}</Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">

        </Text>
        <Text
          fontSize="md"
          color={textColor}
          fontWeight="bold"
          pb=".2rem"
        >{version}</Text>

        {/* <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            pb=".2rem"
          >{`${progression}%`}</Text>
          <Progress
            colorScheme={progression === 100 ? "red" : "green"}
            size="xs"
            value={progression}
            borderRadius="15px"
          />
        </Flex> */}
      </Td>
      {/* <Td>
        <Button p="0px" bg="transparent" onClick={() => alert('click')}>
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TablePort;
