import { createIcon } from "@chakra-ui/icons";

export const AlienLogo = createIcon({
  displayName: "AlienLogo",
  viewBox: "0 0 512 512",
  path: (
    <g>
      <path fill="#7f4aff" d="M111.051 442.516h36.658v36.658h-36.658zm-1.291-290.235h36.658v-36.658H73.102v73.316h36.658zm36.658-109.974H109.76v36.658h36.658zm255.715 146.632h36.658v-73.316h-73.316v36.658h36.658zm0-146.632h-36.658v36.658h36.658zm73.209 109.867v108.549h-73.028v-36.23h-36.082V188.28h-36.97v-73.316h-36.658v73.316h-73.316v-73.316H182.63v73.316h-36.658v36.213H109.76v36.23H36.658V152.281H0v108.656h36.299v36.444h73.461v108.904h37.949v36.23h71.77v-36.23h73.898v36.23h71.77v-36.23h37.166V297.381h73.135V260.83H512V152.174zM365.475 479.173h36.658v-36.658h-36.658z" />
      <path fill="#132028" d="M218.573 332.941h-72.6v-72.002h72.6zm147.659-72.002h-72.201v72.002h72.201z" />
    </g>
  ),
});

export const WaxLogo = createIcon({
  displayName: "WaxLogo",
  viewBox: "0 0 32 32",
  path: (
    <g>
      <circle cx="16" cy="16" r="16" fill="#f89022" />
      <path fill="#fff" d="M28 18.962h-2.364l-1.677-1.436l-1.671 1.43H20.29l-.955-1.16h-3.292l.833-1.029h1.619l-1.233-1.508l-4.305 5.241H10.96l1.246-1.522h-2.081L9 15.824l-1.116 3.13H5.773L4 14.041h1.62l1.192 3.346L8 14.056h2l1.185 3.324l1.184-3.325h1.625l-1.788 4.923l.398-.486l3.652-4.445h2.01l3.039 3.708l1.462-1.257L18.14 12.5h2.375zm-2.345-2.972l-1.115-.956l1.113-.948l2.25.002z" />
    </g>
  ),
});

export const TLMLogo = createIcon({
  displayName: "TLMLogo",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="#eacb7d" fill-rule="evenodd" d="m9.516 5.47l1.334-2.746a1.275 1.275 0 0 1 2.3 0l1.334 2.746c-.473 1.281-1.407 2.154-2.482 2.155H12c-1.076 0-2.011-.873-2.484-2.155m-.508 1.045L7.865 8.866a7.5 7.5 0 0 1 2.162-.979a4.7 4.7 0 0 1-1.019-1.372m-4.483 9.227L3.6 17.644a3.1 3.1 0 0 1 1.611.673a7.5 7.5 0 0 1-.686-2.575M5.415 22h-2.56c-.632 0-1.045-.67-.766-1.244l1.216-2.506a2.5 2.5 0 0 1 2.446 2.514A2.5 2.5 0 0 1 5.415 22m.7 0h2.883a7.5 7.5 0 0 1-2.72-2.026A3.13 3.13 0 0 1 6.114 22m8.888 0h2.257a3.1 3.1 0 0 1-.26-1.285A7.5 7.5 0 0 1 15.002 22m5.726-3.676l1.182 2.432c.279.573-.134 1.244-.766 1.244h-3.186a2.5 2.5 0 0 1-.194-2.078q.425-.51.756-1.09a2.5 2.5 0 0 1 2.208-.508m-.333-.688l-.921-1.894a7.5 7.5 0 0 1-.48 2.093a3.1 3.1 0 0 1 1.4-.199m-4.262-8.77a7.5 7.5 0 0 0-2.161-.979a4.7 4.7 0 0 0 1.019-1.372zm-3.51 12.478a6.3 6.3 0 0 1-1.25 0V12H6.587a6.3 6.3 0 0 1 .95-1.25h8.927c.368.375.687.795.95 1.25h-4.788zm.626-.094a6.252 6.252 0 0 0 4.48-8.625h-4.48zM12 8.875a6.22 6.22 0 0 1 3.75 1.25h-7.5A6.22 6.22 0 0 1 12 8.875m-5.73 3.75a6.253 6.253 0 0 0 4.48 8.625v-8.625z" clip-rule="evenodd" />
    </g>
  ),
});

export const VersionIcon = createIcon({
  displayName: "VersionIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none" fill-rule="evenodd">
      <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
      <path fill="currentColor" fill-rule="nonzero" d="m12.935 2.878l7.181 3.796c1.066.563 1.066 2.09 0 2.652L18.842 10l1.274.674c1.066.563 1.066 2.09 0 2.652L18.842 14l1.274.674c1.066.563 1.066 2.09 0 2.652l-7.181 3.796a2 2 0 0 1-1.87 0l-7.181-3.796c-1.066-.563-1.066-2.089 0-2.652L5.159 14l-1.275-.674c-1.066-.563-1.066-2.089 0-2.652L5.159 10l-1.275-.674c-1.066-.563-1.066-2.089 0-2.652l7.181-3.796a2 2 0 0 1 1.87 0m3.767 12.253l-3.767 1.991a2 2 0 0 1-1.87 0l-3.767-1.99L5.655 16L12 19.354L18.346 16zm0-4l-3.767 1.992a2 2 0 0 1-1.707.076l-.163-.076l-3.767-1.992l-1.643.87L12 15.353L18.346 12zM12 4.646L5.655 8L12 11.354L18.346 8z" />
    </g>
  ),
});

export const ActionIcon = createIcon({
  displayName: "VersionIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="currentColor" d="M18 3H6.834a3 3 0 1 0 0 2H18a3 3 0 0 1 0 6h-3.168a3 3 0 0 0-5.639 0H6a5 5 0 0 0 0 10h6v3l4-4l-4-4v3H6a3 3 0 0 1 0-6h3.203a3 3 0 0 0 5.629 0H18a5 5 0 0 0 0-10M4 5a1 1 0 1 1 1-1a1 1 0 0 1-1 1m8 8a1 1 0 1 1 1-1a1 1 0 0 1-1 1" />
      <path fill="currentColor" d="M20 17a3 3 0 1 0 3 3a3 3 0 0 0-3-3m0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1" />
    </g>
  ),
});



export const ServerIcon = createIcon({
  displayName: "ServerIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="currentColor" d="M2 4.6v4.8c0 .9.5 1.6 1.2 1.6h17.7c.6 0 1.2-.7 1.2-1.6V4.6C22 3.7 21.5 3 20.8 3H3.2C2.5 3 2 3.7 2 4.6M10 8V6H9v2zM5 8h2V6H5zm15 1H4V5h16zM2 14.6v4.8c0 .9.5 1.6 1.2 1.6h17.7c.6 0 1.2-.7 1.2-1.6v-4.8c0-.9-.5-1.6-1.2-1.6H3.2c-.7 0-1.2.7-1.2 1.6m8 3.4v-2H9v2zm-5 0h2v-2H5zm15 1H4v-4h16z" />
    </g>
  ),
});

export const PoolIcon = createIcon({
  displayName: "PoolIcon",
  viewBox: "0 0 36 36",
  path: (
    <g>
      <path fill="currentColor" d="M31.2 13.4a14 14 0 0 1 .75 3.6H17.49L8.3 28.07A14 14 0 0 1 22.61 4.8a7.4 7.4 0 0 1 .58-1.92a16.06 16.06 0 1 0 9.93 9.93a7.4 7.4 0 0 1-1.92.59M18 32a13.9 13.9 0 0 1-8.16-2.65L18.43 19h13.52A14 14 0 0 1 18 32" class="clr-i-outline--badged clr-i-outline-path-1--badged" />
      <circle cx="30" cy="6" r="5" fill="currentColor" class="clr-i-outline--badged clr-i-outline-path-2--badged clr-i-badge" />
      <path fill="none" d="M0 0h36v36H0z" />
    </g>
  ),
});

export const MonitorIcon = createIcon({
  displayName: "MonitorIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="currentColor" d="M10 16h4v0h-4z">
        <animate fill="freeze" attributeName="d" begin="0.6s" dur="0.2s" values="M10 16h4v0h-4z;M10 16h4v6h-4z" />
      </path>
      <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path fill="currentColor" fill-opacity="0" stroke-dasharray="72" stroke-dashoffset="72" d="M12 17h-10v-14h20v14Z">
          <animate fill="freeze" attributeName="fill-opacity" begin="1.1s" dur="0.15s" values="0;0.3" />
          <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="72;0" />
        </path>
        <path stroke-dasharray="4" stroke-dashoffset="4" d="M12 21h3M12 21h-3">
          <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.8s" dur="0.2s" values="4;0" />
        </path>
      </g>
    </g>
  ),
});

export const BanHistoryIcon = createIcon({
  displayName: "VersionIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="currentColor" d="M18.5 18.8V16h-1v3.2l2.15 2.15l.7-.7zM12 10.85l5.925-3.425L12 4L6.075 7.425zm-9 6.275V6.875L12 1.7l9 5.175V12h-2V9.1l-7.025 4.05L5 9.1v6.85l6.025 3.475v2.3zM18 24q-2.075 0-3.537-1.463T13 19t1.463-3.537T18 14t3.538 1.463T23 19t-1.463 3.538T18 24m-6.975-12.275" />
    </g>
  ),
});

export const MoonIcon = createIcon({
  displayName: "PoolIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <g fill="#e1c409" fill-opacity="0">
        <path d="M15.22 6.03l2.53-1.94L14.56 4L13.5 1l-1.06 3l-3.19.09l2.53 1.94l-.91 3.06l2.63-1.81l2.63 1.81z">
          <animate id="lineMdMoonFilledLoop0" fill="freeze" attributeName="fill-opacity" begin="0.7s;lineMdMoonFilledLoop0.begin+6s" dur="0.4s" values="0;1" />
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+2.2s" dur="0.4s" values="1;0" />
        </path>
        <path d="M13.61 5.25L15.25 4l-2.06-.05L12.5 2l-.69 1.95L9.75 4l1.64 1.25l-.59 1.98l1.7-1.17l1.7 1.17z">
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+3s" dur="0.4s" values="0;1" />
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+5.2s" dur="0.4s" values="1;0" />
        </path>
        <path d="M19.61 12.25L21.25 11l-2.06-.05L18.5 9l-.69 1.95l-2.06.05l1.64 1.25l-.59 1.98l1.7-1.17l1.7 1.17z">
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+0.4s" dur="0.4s" values="0;1" />
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+2.8s" dur="0.4s" values="1;0" />
        </path>
        <path d="M20.828 9.731l1.876-1.439l-2.366-.067L19.552 6l-.786 2.225l-2.366.067l1.876 1.439L17.601 12l1.951-1.342L21.503 12z">
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+3.4s" dur="0.4s" values="0;1" />
          <animate fill="freeze" attributeName="fill-opacity" begin="lineMdMoonFilledLoop0.begin+5.6s" dur="0.4s" values="1;0" />
        </path>
      </g>
      <path fill="#e1c409" fill-opacity="0" stroke="#e1c409" stroke-dasharray="56" stroke-dashoffset="56" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 6 C7 12.08 11.92 17 18 17 C18.53 17 19.05 16.96 19.56 16.89 C17.95 19.36 15.17 21 12 21 C7.03 21 3 16.97 3 12 C3 8.83 4.64 6.05 7.11 4.44 C7.04 4.95 7 5.47 7 6 Z">
        <animate fill="freeze" attributeName="fill-opacity" begin="1.5s" dur="0.5s" values="0;1" />
        <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="56;0" />
      </path>
    </g>
  ),
});

export const DayIcon = createIcon({
  displayName: "PoolIcon",
  viewBox: "0 0 512 512",
  path: (
    <g>
      <defs>
        <linearGradient id="meteoconsClearDayFill0" x1="150" x2="234" y1="119.2" y2="264.8" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fbbf24" />
          <stop offset=".5" stop-color="#fbbf24" />
          <stop offset="1" stop-color="#f59e0b" />
        </linearGradient>
        <symbol id="meteoconsClearDayFill1" viewBox="0 0 384 384">
          <circle cx="192" cy="192" r="84" fill="url(#meteoconsClearDayFill0)" stroke="#f8af18" stroke-miterlimit="10" stroke-width="6" />
          <path fill="currentColor" stroke="#fbbf24" stroke-linecap="round" stroke-miterlimit="10" stroke-width="24" d="M192 61.7V12m0 360v-49.7m92.2-222.5l35-35M64.8 319.2l35.1-35.1m0-184.4l-35-35m254.5 254.5l-35.1-35.1M61.7 192H12m360 0h-49.7">
            <animateTransform additive="sum" attributeName="transform" dur="6s" repeatCount="indefinite" type="rotate" values="0 192 192; 45 192 192" />
          </path>
        </symbol>
      </defs>
      <use width="384" height="384" href="#meteoconsClearDayFill1" transform="translate(64 64)" />
    </g>
  ),
});

export const AdobexdLogo = createIcon({
  displayName: "AdobexdLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z"
        fill="#470137"
      />
      <path
        d="M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z"
        fill="#FF61F6"
      />
    </g>
  ),
});

export const AtlassianLogo = createIcon({
  displayName: "AtlassianLogo",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        d="M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z"
        fill="#2684FF"
      />
      <path
        d="M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z"
        fill="#2684FF"
      />
    </g>
  ),
});

export const CartIcon = createIcon({
  displayName: "CartIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z"
    />
  ),
});

export const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
});
export const CreativeTimLogo = createIcon({
  displayName: "CreativeTimLogo",
  viewBox: "0 0 100 100",
  path: (
    <g
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      version="1.1"
    >
      <g
        id="logo-creative-tim-black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon"
          transform="translate(15.000000, 19.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M66.4346744,1.49018376e-13 L3.56968951,1.49018376e-13 C1.59820443,1.49018376e-13 -2.98036753e-13,1.59820443 -2.98036753e-13,3.56968951 L-2.98036753e-13,33.5035241 C-2.98036753e-13,35.4750091 1.59820443,37.0732136 3.56968951,37.0732136 L16.4677598,37.0732136 L16.4677598,66.4346744 C16.4666001,67.3810129 16.8417189,68.288989 17.5104719,68.9585615 C18.1792249,69.628134 19.0867408,70.0043639 20.03308,70.0043639 L49.9669146,70.0043639 C51.9383997,70.0043639 53.5366041,68.4061595 53.5366041,66.4346744 L53.5366041,37.0863214 L66.4346744,37.0863214 C67.3810136,37.0863221 68.2885296,36.7100915 68.9572826,36.0405189 C69.6260355,35.3709464 70.0011544,34.4629704 70,33.5166319 L70,3.56968951 C70.0011544,2.62335099 69.6260355,1.71537495 68.9572826,1.04580242 C68.2885296,0.37622988 67.3810136,1.49018376e-13 66.4346744,1.49018376e-13 Z M4.13769396,4.14206323 L32.9311503,4.14206323 L32.9311503,32.9355196 L4.13769396,32.9355196 L4.13769396,4.14206323 Z M49.3989102,65.86667 L20.6054538,65.86667 L20.6054538,37.0863214 L49.3989102,37.0863214 L49.3989102,65.86667 Z M65.8623007,32.9355196 L37.0688443,32.9355196 L37.0688443,4.14206323 L65.8623007,4.14206323 L65.8623007,32.9355196 Z"
            id="Shape"
          />
          <path
            d="M23.6988934,23.3056595 C24.5142794,24.0708897 25.7898859,24.0506562 26.5805917,23.2599503 C27.3712976,22.4692445 27.3915311,21.193638 26.6263009,20.378252 L20.015603,13.767554 C19.2050095,12.9645312 17.8987889,12.9645312 17.0881954,13.767554 L10.4600204,20.378252 C9.90907005,20.8953125 9.68342943,21.6713831 9.87125954,22.4032422 C10.0590897,23.1351013 10.6305787,23.7065903 11.3624377,23.8944204 C12.0942968,24.0822505 12.8703675,23.8566099 13.387428,23.3056595 L18.5344222,18.1586654 L23.6988934,23.3056595 Z"
            id="Path"
          />
          <path
            d="M46.3185783,23.3056595 L51.4655725,18.1586654 L56.6125667,23.3056595 C57.4279526,24.0708897 58.7035592,24.0506562 59.494265,23.2599503 C60.2849709,22.4692445 60.3052044,21.193638 59.5399742,20.378252 L52.9292763,13.767554 C52.1186828,12.9645312 50.8124622,12.9645312 50.0018687,13.767554 L43.3911708,20.378252 C42.6259406,21.193638 42.6461741,22.4692445 43.43688,23.2599503 C44.2275858,24.0506562 45.5031924,24.0708897 46.3185783,23.3056595 L46.3185783,23.3056595 Z"
            id="Path"
          />
          <path
            d="M29.8508185,46.6987044 C29.0342976,45.9346441 27.7586832,45.9567597 26.9691385,46.7486649 C26.1795937,47.54057 26.1612859,48.8162446 26.9277802,49.6304812 L33.5384782,56.2368099 C33.9262423,56.6258256 34.452915,56.8444779 35.002182,56.8444779 C35.5514489,56.8444779 36.0781217,56.6258256 36.4658857,56.2368099 L43.0765837,49.6304812 C43.6002952,49.1075502 43.8052442,48.3449453 43.6142284,47.6299323 C43.4232126,46.9149194 42.8652519,46.3561259 42.1505246,46.1640439 C41.4357974,45.971962 40.6728876,46.1757734 40.1491761,46.6987044 L35.002182,51.8500678 L29.8508185,46.6987044 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  ),

  //   <chakra.svg
  //   height="50px"
  //   width="50px"
  //   viewBox="0 0 100 100"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   color={fill}
  //   {...props}
  // >
  // </chakra.svg>
});

export const CreditIcon = createIcon({
  displayName: "CreditIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z"
    />
  ),
});

export const DashboardLogo = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 1000 257",
  path: (
    <g width="998" height="257" viewBox="0 0 998 257" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z"
          fill="currentColor"
        />
        <path
          d="M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z"
          fill="currentColor"
        />
        <path
          d="M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z"
          fill="currentColor"
        />
        <path
          d="M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z"
          fill="currentColor"
        />
        <path
          d="M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z"
          fill="currentColor"
        />
        <path
          d="M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z"
          fill="currentColor"
        />
        <rect width="257" height="257" rx="128.5" fill="#4FD1C5" />
        <path
          d="M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="128.5"
          y1="0"
          x2="128.5"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7BCBD4" />
          <stop offset="1" stopColor="#29C6B7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="997.832" height="257" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export const DashboardLogoWhite = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 163.5 42",
  path: (
    <g fill="none">
      <path
        d="M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z"
        fill="#fff"
      />
      <path
        d="M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z"
        fill="#3BCBBE"
      />
    </g>
  ),
});

export const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M18.809 10.344h-6.153a2.11 2.11 0 01-2.11-2.11V2.083a.176.176 0 00-.175-.176H6.328A2.812 2.812 0 003.516 4.72v14.063a2.812 2.812 0 002.812 2.812h9.844a2.812 2.812 0 002.812-2.813V10.52a.176.176 0 00-.175-.176z"
      />
      <path
        fill="currentColor"
        d="M18.423 8.789l-6.32-6.32a.088.088 0 00-.15.062v5.705a.703.703 0 00.703.703h5.705a.088.088 0 00.062-.15z"
      />
    </g>
  ),
});

export const GlobeIcon = createIcon({
  displayName: "GlobeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109a9.14 9.14 0 100 18.281 9.14 9.14 0 000-18.281z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109C8.698 2.109 6.3 6.2 6.3 11.249c0 5.048 2.4 9.14 4.951 9.14 2.552 0 4.951-4.092 4.951-9.14 0-5.048-2.399-9.14-4.95-9.14z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904M17.344 17.344c-1.68-1.193-3.796-1.904-6.094-1.904-2.298 0-4.413.711-6.094 1.904"
        strokeWidth=".75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109v18.28M20.39 11.249H2.11"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
    </g>
  ),
});

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path fill="currentColor" fill-opacity="0" d="M10 14h4v6h-4Z">
        <animate fill="freeze" attributeName="fill-opacity" begin="1.2s" dur="0.15s" values="0;0.3" />
      </path>
      <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path stroke-dasharray="16" stroke-dashoffset="16" d="M5 21h14">
          <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.2s" values="16;0" />
        </path>
        <path stroke-dasharray="16" stroke-dashoffset="16" d="M5 21v-13M19 21v-13">
          <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.2s" dur="0.2s" values="16;0" />
        </path>
        <path stroke-dasharray="24" stroke-dashoffset="24" d="M9 21v-8h6v8">
          <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.4s" dur="0.4s" values="24;0" />
        </path>
        <path stroke-dasharray="32" stroke-dashoffset="32" d="M2 10l10 -8l10 8">
          <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.7s" values="32;0" />
        </path>
      </g>
    </g>
  ),
});

export const InvisionLogo = createIcon({
  displayName: "InvisionLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M18.687 0H2.313A1.813 1.813 0 00.5 1.811v16.374C.5 19.188 1.312 20 2.313 20h16.374a1.813 1.813 0 001.813-1.813V1.812A1.813 1.813 0 0018.687 0z"
        fill="#DC395F"
      />
      <path
        d="M7.184 6.293c.665 0 1.222-.522 1.222-1.204 0-.683-.557-1.204-1.222-1.204-.665 0-1.222.521-1.222 1.204 0 .682.557 1.204 1.222 1.204zM4.65 12.739a4.134 4.134 0 00-.108.905c0 1.06.575 1.764 1.797 1.764 1.013 0 1.834-.602 2.426-1.573l-.361 1.449h2.012l1.15-4.612c.287-1.168.844-1.774 1.689-1.774.665 0 1.078.413 1.078 1.096 0 .197-.018.413-.09.646l-.593 2.12c-.09.306-.126.611-.126.899 0 1.006.593 1.742 1.833 1.742 1.06 0 1.904-.682 2.371-2.317l-.79-.305c-.395 1.095-.737 1.293-1.006 1.293-.27 0-.414-.18-.414-.538 0-.162.037-.342.09-.558l.575-2.065c.144-.485.198-.915.198-1.31 0-1.546-.934-2.352-2.066-2.352-1.06 0-2.138.956-2.677 1.962l.395-1.806H8.962L8.53 8.996h1.438l-.885 3.544c-.695 1.545-1.972 1.57-2.132 1.534-.264-.06-.432-.159-.432-.5 0-.198.036-.482.126-.823l1.348-5.346H4.579l-.431 1.591h1.419L4.65 12.74"
        fill="#fff"
      />
    </g>
  ),
});

export const JiraLogo = createIcon({
  displayName: "JiraLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M20.26 10.42l-8.863-8.93-.86-.865-6.67 6.722L.814 10.42a.827.827 0 000 1.162l6.096 6.14 3.627 3.654 6.67-6.722.105-.104 2.947-2.964a.824.824 0 000-1.166zm-9.722 3.649L7.493 11l3.045-3.068L13.582 11l-3.044 3.068z"
        fill="#2684FF"
      />
      <path
        d="M10.537 7.932a5.184 5.184 0 01-1.502-3.637A5.185 5.185 0 0110.515.65L3.852 7.36l3.626 3.654 3.06-3.082z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.59 10.992l-3.053 3.076a5.186 5.186 0 011.502 3.653c0 1.37-.54 2.683-1.502 3.652l6.682-6.728-3.63-3.653z"
        fill="url(#paint1_linear)"
      />
    </g>
  ),
});

export const MastercardIcon = createIcon({
  displayName: "MastercardIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="24"
      height="20"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.63158" cy="7.49999" r="6.63158" fill="#EB001B" />
      <circle cx="14.3686" cy="7.49999" r="6.63158" fill="#F79E1B" />
    </svg>
  ),
});

export const PayPalIcon = createIcon({
  displayName: "PayPalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M16.179 2.532C15.142 1.357 13.265.854 10.863.854H3.895a.997.997 0 00-.986.834L.007 19.945a.595.595 0 00.591.686H4.9l1.08-6.8-.033.215a.993.993 0 01.983-.834h2.044c4.017 0 7.16-1.619 8.08-6.3.027-.14.07-.406.07-.406.262-1.732-.001-2.907-.945-3.974z"
      />
      <path
        fill="currentColor"
        d="M18.213 7.393c-.998 4.61-4.184 7.048-9.24 7.048H7.142L5.773 23.1h2.973a.87.87 0 00.862-.731l.035-.184.684-4.297.044-.237a.87.87 0 01.86-.731h.544c3.514 0 6.264-1.416 7.068-5.51.322-1.644.166-3.021-.63-4.017z"
      />
    </g>
  ),
});

export const PersonIcon = createIcon({
  displayName: "PersonIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
      <path d="M16 9a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0" />
      <path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1M3 12c0 2.09.713 4.014 1.908 5.542A8.99 8.99 0 0 1 12.065 14a8.98 8.98 0 0 1 7.092 3.458A9 9 0 1 0 3 12m9 9a8.96 8.96 0 0 1-5.672-2.012A6.99 6.99 0 0 1 12.065 16a6.99 6.99 0 0 1 5.689 2.92A8.96 8.96 0 0 1 12 21" />
    </g>
  ),
});

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path d="M0 0h24v24H0V0z" fill="transparent" />
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
      />
    </g>
  ),
});

export const RocketIcon = createIcon({
  displayName: "RocketIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z"
      />
      <path
        fill="currentColor"
        d="M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z"
      />
    </g>
  ),
});

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="10s"
        repeatCount="indefinite"
      />
    </g>
  ),
});

export const CpuIcon = createIcon({
  displayName: 'CpuIcon',
  viewBox: '0 0 16 16',
  path: (
    <g>
      <path fill="currentColor" d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0m-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
    </g>
  ),
});

export const RamIcon = createIcon({
  displayName: 'RamIcon',
  viewBox: '0 0 256 256',
  path: (
    <g>
      <path fill="currentColor" fill-rule="evenodd" d="M223.977 63.69a6 6 0 0 0-2.295-.446l.001-.001l-188.877.77a6.013 6.013 0 0 0-5.984 6.024l.226 98.86c.008 3.314 2.699 6.015 6.01 6.034l15.326.087v10.633c0 3.315 2.683 6.007 5.993 6.007h25.471s6.319-15.38 23.62-15.38s23.783 15.38 23.783 15.38h74.032a6.003 6.003 0 0 0 5.999-6.008v-8.16h14.388a6.004 6.004 0 0 0 6.01-6.003V69.222a5.98 5.98 0 0 0-1.755-4.237a6 6 0 0 0-1.948-1.294zM42.588 80.586A2 2 0 0 1 44.002 80v.001h44A1.995 1.995 0 0 1 90 82.002l-.13 69.985s-4.127.137-7.667 2.48S77.76 160 77.76 160H42V81.997a2 2 0 0 1 .588-1.412zm62.001-.001a2 2 0 0 1 1.413-.584h44a2 2 0 0 1 1.847 1.232c.1.243.151.503.151.765l-.141 76.006a2.01 2.01 0 0 1-2.013 1.997h-20.189s-4.827-4.457-10.622-6.472c-5.795-2.014-15.035-2.447-15.035-2.447V81.994a2 2 0 0 1 .589-1.41zm61.999 0a2 2 0 0 1 1.414-.584h44a2 2 0 0 1 1.847 1.232c.1.243.151.503.151.765l-.141 76.006A2.004 2.004 0 0 1 211.86 160H166V81.997a2 2 0 0 1 .588-1.412" clip-rule="evenodd" />
    </g>
  ),
});

export const SystemIcon = createIcon({
  displayName: 'SystemIcon',
  viewBox: '0 0 2048 2048',
  path: (
    <g>
      <path fill="currentColor" d="m1378 384l64 128H606l64-128zm256 512l64 128H350l64-128zm-128-256l64 128H478l64-128zm30-512l512 1024v640H0v-640L512 128zM591 256l-448 896h1762l-448-896zm1329 1408v-384H128v384zM512 1408v128H256v-128z" />
    </g>
  ),
});

export const ComputerIcon = createIcon({
  displayName: 'ComputerIcon',
  viewBox: '0 0 50 50',
  path: (
    <g fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="#344054" stroke-width="3" d="M24.896 33.333h.208" />
      <path stroke="#344054" stroke-width="0.5" d="M29.167 22.917h-8.334m8.334-8.334h-8.334z" />
      <path stroke="#306cfe" stroke-width="0.5" d="M35.417 6.25H14.583c-1.15 0-2.083.933-2.083 2.083v33.334c0 1.15.933 2.083 2.083 2.083h20.834c1.15 0 2.083-.933 2.083-2.083V8.333c0-1.15-.933-2.083-2.083-2.083" />
    </g>
  ),
});

export const SlackLogo = createIcon({
  displayName: "SlackLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z"
        fill="#36C5F0"
      />
      <path
        d="M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z"
        fill="#2EB67D"
      />
      <path
        d="M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z"
        fill="#ECB22E"
      />
      <path
        d="M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z"
        fill="#E01E5A"
      />
    </g>
  ),
});

export const SpotifyLogo = createIcon({
  displayName: "SpotifyLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M10.5 0C4.977 0 .5 4.477.5 10s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm4.586 14.422a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.216c3.808-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857zm1.223-2.722a.78.78 0 01-1.072.257c-2.687-1.652-6.786-2.13-9.965-1.166a.78.78 0 01-.973-.519.781.781 0 01.52-.972c3.632-1.102 8.147-.569 11.233 1.329a.78.78 0 01.258 1.072zm.106-2.835C13.19 6.95 7.875 6.775 4.797 7.708a.935.935 0 11-.543-1.79c3.533-1.072 9.404-.865 13.115 1.338a.935.935 0 01-.954 1.609z"
        fill="#2EBD59"
      />
    </g>
  ),
});

export const SupportIcon = createIcon({
  // Doesn't display the full icon without w and h being specified
  displayName: "BuildIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M20.885 5.547a.703.703 0 00-1.122-.176l-2.7 2.702a.708.708 0 01-.995 0l-1.167-1.169a.702.702 0 010-.994l2.689-2.69a.704.704 0 00-.21-1.138c-2.031-.908-4.566-.435-6.164 1.152-1.358 1.348-1.763 3.455-1.11 5.78a.698.698 0 01-.197.703L2.593 16.4a2.82 2.82 0 103.981 3.983l6.754-7.332a.699.699 0 01.693-.2 7.885 7.885 0 002.03.279c1.469 0 2.757-.475 3.686-1.39 1.72-1.695 1.983-4.57 1.148-6.192zM4.623 19.901a1.407 1.407 0 11-.305-2.797 1.407 1.407 0 01.305 2.797z"
    />
  ),
});

export const StatsIcon = createIcon({
  displayName: "StatsIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M4.57 22.297H3.164a1.055 1.055 0 01-1.055-1.054v-6.328a1.055 1.055 0 011.055-1.055H4.57a1.055 1.055 0 011.055 1.055v6.328a1.055 1.055 0 01-1.055 1.054zM14.414 22.296h-1.406a1.055 1.055 0 01-1.055-1.055V10.695a1.055 1.055 0 011.055-1.055h1.406a1.055 1.055 0 011.055 1.055V21.24a1.055 1.055 0 01-1.055 1.055zM19.336 22.297H17.93a1.055 1.055 0 01-1.055-1.055V5.773A1.055 1.055 0 0117.93 4.72h1.406a1.055 1.055 0 011.055 1.054v15.47a1.055 1.055 0 01-1.055 1.054zM9.492 22.297H8.086a1.055 1.055 0 01-1.055-1.055V2.257a1.055 1.055 0 011.055-1.054h1.406a1.055 1.055 0 011.055 1.054v18.985a1.055 1.055 0 01-1.055 1.055z"
    />
  ),
});

export const WalletIcon = createIcon({
  displayName: "WalletIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z"
      />
      <path
        fill="currentColor"
        d="M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z"
      />
    </g>
  ),
});

export const VisaIcon = createIcon({
  displayName: "VisaIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="26"
      height="20"
      viewBox="0 0 30 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6708 2.96349C12.6568 4.15055 13.653 4.81298 14.4034 5.20684C15.1744 5.61099 15.4333 5.87013 15.4304 6.23147C15.4245 6.78459 14.8153 7.02862 14.2452 7.03812C13.2505 7.05475 12.6723 6.74889 12.2125 6.51753L11.8542 8.32341C12.3155 8.55247 13.1696 8.75217 14.0554 8.76087C16.1345 8.76087 17.4947 7.65543 17.5021 5.94145C17.5102 3.76625 14.7087 3.64579 14.7278 2.67348C14.7344 2.37871 14.9956 2.06408 15.568 1.98406C15.8512 1.94368 16.6332 1.91274 17.5198 2.35252L17.8677 0.605286C17.391 0.418253 16.7782 0.239136 16.0153 0.239136C14.0583 0.239136 12.6819 1.35962 12.6708 2.96349ZM21.2115 0.389687C20.8319 0.389687 20.5118 0.628245 20.3691 0.99433L17.3991 8.63249H19.4767L19.8901 7.40183H22.429L22.6689 8.63249H24.5L22.9021 0.389687H21.2115ZM21.5021 2.61641L22.1016 5.7116H20.4596L21.5021 2.61641ZM10.1518 0.389687L8.51418 8.63249H10.4939L12.1308 0.389687H10.1518ZM7.22303 0.389687L5.16233 6.00003L4.32878 1.22966C4.23097 0.697187 3.84472 0.389687 3.41579 0.389687H0.0471011L0 0.629037C0.691574 0.790671 1.47729 1.0514 1.95326 1.33033C2.24457 1.50067 2.32775 1.64964 2.42336 2.05458L4.00214 8.63249H6.0945L9.3021 0.389687H7.22303Z"
        fill="#4318FF"
      />
    </svg>
  ),
});
