// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
  Skeleton,
  Box
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  ServerIcon,
  AlienLogo,
  WaxLogo,
  TLMLogo
} from "components/Icons/Icons.js";
import React, { useState, useEffect } from 'react';
import { dashboardTableData, timelineData, updateTimelineData } from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import UpdateTimeline from "./components/updateTimeline";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";

import axios from 'axios';

export default function Dashboard() {
  const iconBoxInside = useColorModeValue("cyan", "cyan");

  const [dashboardData, setDashboardData] = useState(false);

  useEffect(
    () => {
      axios.get(`http://localhost:3003/api/dashboard`, {
      }).then((res) => {
        setDashboardData(res.data);
      }).catch((err) => console.log(err));
    },
    []
  );

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
        <Skeleton height="83px" isLoaded={dashboardData}>
          <MiniStatistics
            title={"Active Miners"}
            amount={dashboardData ? dashboardData.activeMiner : "0"}
            percentage={dashboardData ? parseFloat(dashboardData.activeChange).toFixed(2) : 0}
            icon={<AlienLogo h={"32px"} w={"32px"} color={iconBoxInside} />}
          />
        </Skeleton>
        <Skeleton height="83px" isLoaded={dashboardData}>
          <MiniStatistics
            title={"WAX Price"}
            amount={dashboardData ? `฿${parseFloat(dashboardData.waxTHB).toFixed(4)}` : "0"}
            percentage={dashboardData ? parseFloat(dashboardData.waxChange).toFixed(2) : 0}
            icon={<WaxLogo h={"32px"} w={"32px"} color={iconBoxInside} />}
          />
        </Skeleton>
        <Skeleton height="83px" isLoaded={dashboardData}>
          <MiniStatistics
            title={"TLM Price"}
            amount={dashboardData ? `฿${parseFloat(dashboardData.tlmTHB).toFixed(4)}` : "0"}
            percentage={dashboardData ? parseFloat(dashboardData.tlmChange).toFixed(2) : 0}
            icon={<TLMLogo h={"32px"} w={"32px"} color={iconBoxInside} />}
          />
        </Skeleton>
        <Skeleton height="83px" isLoaded={dashboardData}>
          <MiniStatistics
            title={"Release Version"}
            amount={dashboardData ? dashboardData.serverVersion : "0"}
            percentage={0}
            icon={<ServerIcon h={"32px"} w={"32px"} color={iconBoxInside} />}
          />
        </Skeleton>
      </SimpleGrid>
      <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <BuiltByDevelopers
          title={""}
          name={"Discord Community"}
          description={
            "เข้าร่วม Discord ของบอท"
          }
          image={
            <Image
              src={"https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0a6ca814282eca7172c6_icon_clyde_white_RGB.svg"}
              alt='Discord image'
              minWidth={{ sm: '50px', md: "100px", lg: "auto" }}
              maxWidth={{ sm: '50px', md: "200px", lg: "100px" }}
              color={"blackAlpha.400"}
            />
          }
        // 
        />
        <WorkWithTheRockets
          backgroundImage={"https://vos.line-scdn.net/strapi-cluster-instance-bucket-84/brand_site_1_cc7b08939a.png"}
          title={"Line Openchat Community"}
          description={
            "พัฒนาบอทให้ตอบโจทย์ตัวลูกค้า สามารถเสนอสิ่งที่ควรแก้ไข สิ่งที่ควรเพิ่มเข้ามาในบอท ให้ครอบคลุมการใช้งาน"
          }
        />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}
      >
        {/* <ActiveUsers
          title={"Active Users"}
          percentage={23}
          chart={<BarChart />}
        />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={<LineChart />}
        /> */}
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap='60%'>
        {/* <Projects
          title={"Projects"}
          amount={30}
          captions={["Companies", "Members", "Budget", "Completion"]}
          data={dashboardTableData}
        />  */}
        <UpdateTimeline
          title={"Update Timeline"}
          amount={updateTimelineData.length}
          data={updateTimelineData}
        />
      </Grid>
    </Flex>
  );
}
