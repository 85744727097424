// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Link,
  useColorMode,
} from "@chakra-ui/react";
// Assets
import anonymousLogo from "assets/img/anonymous-logo.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { PersonIcon, SettingsIcon, MoonIcon, DayIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import routes from "routes.js";
import { BsSun, BsMoonStarsFill } from 'react-icons/bs';

import { webservice } from '../../service';

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();

  const [user, setUser] = useState(webservice.userData);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user.access_token) {
        axios.post(`http://localhost:3003/api/user`, {
          access_token: user.access_token
        }).then((res) => {
          setUser(res.data);
          webservice.setUser(res.data);
        }).catch((err) => console.log(err));
      }
    },
    [user]
  );

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <InputGroup
        cursor="pointer"
        borderRadius="15px"

        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainTeal },
        }}
        _active={{
          borderColor: { mainTeal },
        }}
      >
        <SidebarResponsive
          logoText={"menu"}
          secondary={props.secondary}
          routes={routes}
          logo={anonymousLogo}
          {...rest}
        />
      </InputGroup>

      <Link onClick={() => user ? null : login()} href={user ? "/#/control/profile" : null}>
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <PersonIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <PersonIcon color={navbarIcon} w="22px" h="22px" me="0px" />
            ) : (
              ""
            )
          }
        >
          <Text display={{ sm: "none", md: "flex", text: "dark" }}>{user ? user.given_name : 'Sign In'}</Text>
        </Button>
      </Link>
      <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      {colorMode === 'light' ? (
        <DayIcon
          cursor="pointer"
          ms={{ base: "16px", xl: "0px" }}
          me="16px"
          onClick={toggleColorMode}
          w="18px"
          h="18px"
        />
      ) : (
        <MoonIcon
          cursor="pointer"
          ms={{ base: "16px", xl: "0px" }}
          me="16px"
          onClick={toggleColorMode}
          w="18px"
          h="18px"
        />

      )}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
