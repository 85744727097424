// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import Authors from "./components/Authors";
import Ports from "./components/Port";
import { monitorTableData } from "variables/general";

function Monitor() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* <Authors
        title={"Authors Table"}
        captions={["Author", "Function", "Status", "Employed", ""]}
        data={tablesTableData}
      /> */}
      <Ports
        title={"Port List"}
        captions={["name", "Total TLM", "Status", "cycle", "Version"]}
        data={monitorTableData}
      />
    </Flex>
  );
}

export default Monitor;