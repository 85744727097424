// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import anonymousLogo from "assets/img/anonymous-logo.png"
import ProfileBgImage from "assets/img/bgprofile.jpg";
import React from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import Projects from "./components/Projects";
import { webservice } from "service";
import Ports from "./components/Port";
import { monitorTableData } from "variables/general";
import Devices from "./components/Devices";
import {
  CpuIcon,
  RamIcon,
  SystemIcon
} from "components/Icons/Icons";

function Profile() {
  // Chakra color mode
  console.log(webservice);
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={anonymousLogo}
        name={webservice ? webservice.userData.given_name : ""}
        email={webservice ? webservice.userData.email : ""}
        tabs={[
          {
            name: "OVERVIEW",
            icon: <FaCube w='100%' h='100%' />,
          },
          {
            name: "TEAMS",
            icon: <IoDocumentsSharp w='100%' h='100%' />,
          },
          {
            name: "PROJECTS",
            icon: <FaPenFancy w='100%' h='100%' />,
          },
        ]}
      />
      <Grid gap='22px'>
        <Devices
          title="Devices"
          device={[
            // {
            //   id: '9172866407828add960d3ed299a995e5dd6896f9f775fe814af920bae6eea4a0',
            //   info: [
            //     {
            //       name: "Gen Intel® Core™ i9-11900KF",
            //       icon: <CpuIcon w='16px' h='16px' />,
            //     },
            //     {
            //       name: "DDR4 3600Mhz 32GB",
            //       icon: <RamIcon w='16px' h='16px' />,
            //     },
            //     {
            //       name: "Microsoft Windows 10 Pro",
            //       icon: <SystemIcon w='16px' h='16px' />,
            //     },
            //   ]
            // },
            // {
            //   id: '99a995e5dd6896f9f775fe814af920bae6eea4a099a995e5dd6896f9f775fe8afc',
            //   info: [
            //     {
            //       name: "Gen Intel® Core™ i9-14900K",
            //       icon: <CpuIcon w='16px' h='16px' />,
            //     },
            //     {
            //       name: "DDR5 7200Mhz 128GB",
            //       icon: <RamIcon w='16px' h='16px' />,
            //     },
            //     {
            //       name: "Microsoft Windows 10 Pro",
            //       icon: <SystemIcon w='16px' h='16px' />,
            //     },
            //   ]
            // }
          ]}
        />
      </Grid>
      <Grid gap='22px'>
        <Ports
          title={"Port List"}
          captions={["name", "Total TLM", "Status", "cycle", "Version", ""]}
          data={monitorTableData}
        />
        {/* <PlatformSettings
          title={"Account Settings"}
          subtitle1={"ACCOUNT"}
          subtitle2={"APPLICATION"}
        />
        <ProfileInformation
          title={"Profile Information"}
          description={
            "Hi, I’m Esthera Jackson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          }
          name={"Esthera Jackson"}
          mobile={"(44) 123 1234 123"}
          email={"esthera@simmmple.com"}
          location={"United States"}
        /> */}
        {/* <Conversations title={"Conversations"} /> */}
      </Grid>
      {/* <Projects title={"Projects"} description={"Architects design houses"} /> */}
    </Flex>
  );
}

export default Profile;
